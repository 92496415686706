/* eslint-disable index/forbid */
import type { IConfig, EnvConfig } from './types';

const FRA_REGION = 'fra'; // production only
const FRA_B_REGION = 'fra-b'; // labs only
const EU_SUFFIX = 'eu';

const NO_REGION_SPECIFIED = 'no-region';

function getRegionSuffix(): string {
  // we NO LONGER put region in the api domain urls
  // (with some exceptions below)
  return '';
}

function getRegionSuffixForWebsocket(region: string): string {
  // websocket api url needs "-fra" suffix as an exception
  if (region === FRA_REGION) {
    return `-${FRA_REGION}`;
  }

  return '';
}

function getRegionSuffixForEmailinium(region: string): string {
  // add "-fra" suffix for production only
  if (region === FRA_REGION) {
    return `-${FRA_REGION}`;
  }

  return '';
}

function getRegionSuffixForMlGateway(region: string): string {
  // add "-eu" suffix for production only
  if (region === FRA_REGION) {
    return `-${EU_SUFFIX}`;
  }

  return '';
}

function getRegionSuffixForInsights(region: string): string {
  // add "-eu" suffix for production and labs
  if (region === FRA_REGION || region === FRA_B_REGION) {
    return `-${EU_SUFFIX}`;
  }

  return '';
}

function replaceRegion(url: string, region: string): string {
  return url.replace('{{dc}}', region);
}

function throwIfNoRegion(region: string, message: string): void {
  if (region === NO_REGION_SPECIFIED) {
    throw new Error(message);
  }
}

export function createConfig(env: EnvConfig, region: string = NO_REGION_SPECIFIED): IConfig {
  const schemas: IConfig['schemas'] = {
    api: env.api_address_schema,
    queueApi: env.queue_address_schema,
    ticketsDomain: env.tickets_domain_schema,
    mlGatewayApiUrl: env.ml_gateway_api_schema,
    emailiniumApiUrl: env.emailinium_api_schema,
    insightsApiUrl: env.insights_api_schema,
    aiAgentsApiUrl: env.ai_agents_api_schema,
    websocketApiUrl: env.platform_rtm_api_schema,
  };
  /* eslint-disable @typescript-eslint/naming-convention */
  const reportsApplications = {
    ChatDuration: env.reports_applications.chat_duration,
    ChatResponseTime: env.reports_applications.chat_response_time,
    StaffingPrediction: env.reports_applications.staffing_prediction,
    Activity: env.reports_applications.agent_activity,
    AgentsPerformance: env.reports_applications.agents_performance,
  };
  /* eslint-enable @typescript-eslint/naming-convention */

  return {
    schemas,
    reportsApplications,

    api: replaceRegion(schemas.api, getRegionSuffix()),
    queueApi: replaceRegion(schemas.queueApi, getRegionSuffix()),
    ticketsDomain: replaceRegion(schemas.ticketsDomain, getRegionSuffix()),

    get emailiniumApiUrl(): string {
      if (region === NO_REGION_SPECIFIED) {
        throw new Error('Region not specified for emailiniumApiUrl.');
      }

      return replaceRegion(schemas.emailiniumApiUrl, getRegionSuffixForEmailinium(region));
    },

    get mlGatewayApiUrl(): string {
      throwIfNoRegion(region, 'Region not specified for mlGatewayApiUrl.');

      return replaceRegion(schemas.mlGatewayApiUrl, getRegionSuffixForMlGateway(region));
    },
    get websocketApiUrl(): string {
      throwIfNoRegion(region, 'Region not specified for websocketApiUrl.');

      return replaceRegion(schemas.websocketApiUrl, getRegionSuffixForWebsocket(region));
    },
    get insightsApiUrl(): string {
      throwIfNoRegion(region, 'Region not specified for insightsApiUrl.');

      return replaceRegion(schemas.insightsApiUrl, getRegionSuffixForInsights(region));
    },
    get aiAgentsApiUrl(): string {
      throwIfNoRegion(region, 'Region not specified for aiAgentsApiUrl.');

      return replaceRegion(schemas.aiAgentsApiUrl, getRegionSuffixForMlGateway(region));
    },

    accounts: env.accounts_address_default,
    accountsClientId: env.accounts_client_id,
    dashboardAddress: env.dashboard_address,
    devPlatform: env.dev_platform_address,
    platformUrl: env.platform_url,
    devMarketplace: env.dev_marketplace_address,
    publicDevMarketplace: env.public_dev_marketplace_address,
    fbMsgConfig: env.fbmsg_config_address,
    billing: env.billing_address,
    recurlyUrl: env.recurly_url,
    recurlyKey: env.recurly_key,
    inactivityReasonAppId: env.inactivity_reason_app_id,
    facebookMessengerApp: env.facebook_messenger_app_id,
    appleBusinessChatAppId: env.apple_business_chat_app_id,
    googleAppId: env.google_app_id,
    instagramAppId: env.instagram_app_id,
    helpDeskAppWidgetId: env.helpdesk_app_widget_id,
    helpDeskAppClientId: env.helpdesk_app_client_id,
    chatBotAppId: env.chatbot_app_id,
    chatBotAppUrl: env.chatbot_app_url,
    chatBotApiUrl: env.chatbot_api_url,
    globalScraperApiUrl: env.global_scraper_api_url,
    chatbotInLivechatApiUrl: env.chatbot_in_livechat_api_url,
    shopifyCustomerOverviewSectionId: env.shopify_customer_overview_section_id,
    helpdeskApiUrl: env.helpdesk_api_url,
    helpdeskAppUrl: env.helpdesk_app_url,
    helpdeskFormsUrl: env.helpdesk_forms_url,
    helpdeskClientId: env.helpdesk_client_id,
    facebookMessengerApiUrl: env.facebook_messenger_api_url,
    livechatPageUrl: env.livechat_page_url,
    knowledgeBaseAppId: env.knowledgebase_app_id,
    knowledgeBaseApiUrl: env.knowledgebase_api_url,
    knowledgeBaseAppUrl: env.knowledgebase_app_url,
    emailiniumAppId: env.emailinium_app_id,
    helpdeskAppId: env.helpdesk_app_id,
    flashApiUrl: env.flash_api_url,
    customStatusApiUrl: env.custom_status_api_url,
    // configured here:
    // https://console.developers.google.com/apis/credentials/key/119?project=eighth-study-167714&organizationId=264963833845
    googleMapsApiKey: env.google_maps_api_key,
    googleTagManagerApiKey: env.google_tag_manager_api_key,
    googleTagManagerClientId: env.google_tag_manager_client_id,
    connectionTimeout: 75000,
    reconnectDelay: 5000,
    debug: env.debug,
    trackConversions: env.track_conversions,
    trackingCodeURL: env.tracking_code_url,
    samplePageURL: env.sample_page_url,
    directChatLink: env.direct_chat_link,
    qualityPageLink: env.quality_page_link,
    qualityBaseURL: env.quality_base_url,
    env: env.env,
    sentryDSN: env.sentry_dsn,
    enableBusinessEventsLogging: env.enable_business_events_logging,
    baseAppUrl: env.base_app_url,
    cdnPathMarketing: env.cdn_path_marketing,
    cdnPathOrStatic: env.cdn_path_or_static,
    offlineCustomersLimit: env.offline_customers_limit,
    insightsAppId: env.insights_app_id,
    insightsAppHostname: env.insights_app_hostname,
    insightsWebComponentsPath: env.insights_web_components_path,
    beamerId: env.beamer_id,
    externalPaymentProviders: {
      whitelistedAppsIds: env.external_payment_providers.whitelisted_apps_ids,
    },
    websiteApiUrl: env.website_api_url,
    chatsAnalyserAppId: env.chats_analyser_app_id,
    chatsAnalyserNamespace: env.chats_analyser_namespace,
    sprigId: env.sprig_id,
    productEventApiUrl: env.product_event_api_url,
    chatSentimentAppId: env.chat_sentiment_app_id,
    chatSentimentNamespace: env.chat_sentiment_namespace,
    featureControlApiUrl: env.feature_control_api_url,
    openwidgetAppUrl: env.openwidget_app_url,
    autoChatSummaryAppId: env.auto_chat_summary_app_id,
    rtmSentimentWebhooksAppId: env.rtm_sentiment_webhooks_app_id,
    activeSessionsLimit: env.active_sessions_limit,
    concurrentChatsLimit: env.concurrent_chats_limit,
    manuallyAssignedConcurentChatsLimit: env.manually_assigned_concurent_chats_limit,
    whatsappBusinessAppId: env.whatsapp_business_app_id,
    twilioAppId: env.twilio_app_id,
    telegramBusinessAppId: env.telegram_business_app_id,
    crsNamespace: env.crs_namespace,
    facebookMessengerClientId: env.facebook_messenger_client_id,
    whatsappBusinessClientId: env.whatsapp_business_client_id,
    twilioClientId: env.twilio_client_id,
    telegramBusinessClientId: env.telegram_business_client_id,
    mobileAgentAppLiteClientID: env.mobile_agent_app_lite_client_id,
    workflowsBaseApiUrl: env.workflows_base_api_url,
    workflowsWaitlistPath: env.workflows_waitlist_path,
    workflowsPathSuffix: env.workflows_path_suffix,
    teamChatAppUrl: env.teamchat_app_url,
    sellingExperienceApps: env.selling_experience_apps.map((app) => ({
      ...app,
      interviewModal: app.interview_modal,
      messageBoxWidgetId: app.message_box_widget_id,
    })),
  };
}
